import React from "react";

export default ({ primary, secondary }) => {
  const getColour = (color, amount) => {
    return (
      "#" +
      color
        .replace(/^#/, "")
        .replace(/../g, (color) =>
          (
            "0" +
            Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(
              16
            )
          ).substr(-2)
        )
    );
  };

  const colours = {
    primary: primary ? primary : "#FAECD8",
    secondary: secondary ? secondary : "#FF637B",
    primaryDarken: primary ? getColour(primary, -25) : "#F4D7AF",
    secondaryDarken: secondary ? getColour(secondary, -50) : "#E63950",
  };

  return (
    <svg
      width="60"
      height="50"
      viewBox="0 0 60 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.106 14.8233C21.3137 12.4672 17.9974 10.9444 14.6567 10.9446L9.07843 10.9446C8.44773 10.9446 7.89484 11.3681 7.73151 11.9782C7.56793 12.5779 7.83012 13.2289 8.38096 13.547C10.6254 14.8423 13.1259 17.027 13.262 18.0429L23.106 14.8233Z"
        fill={colours.secondary}
      />
      <path
        d="M23.0316 38.6596L13.3348 35.274C13.2028 36.1348 11.6039 37.6761 8.30402 40.1265C7.81532 40.4883 7.62349 41.1198 7.81121 41.683C7.99894 42.2549 8.53283 42.6403 9.13477 42.6403L14.713 42.6403C17.8645 42.6403 21.1302 41.0782 23.0316 38.6596V38.6596Z"
        fill={colours.secondaryDarken}
      />
      <path
        d="M13.9814 36.5937C10.4394 35.5339 10.0768 35.311 9.49099 35.1993C8.8495 35.0596 8.40318 34.5018 8.40318 33.8326L8.40318 19.8867C8.40318 19.2452 8.84976 18.6595 9.49099 18.52C10.0768 18.4083 10.3279 18.2131 13.9814 17.0696L16.7708 19.803L16.7708 33.7488L13.9814 36.5937Z"
        fill="#7C8388"
      />
      <path
        d="M13.9816 36.5937C10.4395 35.5339 10.0769 35.311 9.49117 35.1993C8.84968 35.0596 8.40336 34.5018 8.40336 33.8326L8.40336 26.8594L16.7707 26.8596L16.7707 33.7486L13.9816 36.5937Z"
        fill="#575F64"
      />
      <path
        d="M13.9812 36.5936L13.9812 17.0695C27.9548 12.7743 39.4744 12.8857 48.4552 17.4044L48.4832 26.8596L48.4829 36.1195C39.5301 40.6102 27.983 40.7774 13.9812 36.5936V36.5936Z"
        fill={colours.primary}
      />
      <path
        d="M13.9815 36.5937V26.8594L48.4836 26.8596L48.4836 36.1194C39.5304 40.6103 27.9834 40.7775 13.9815 36.5937V36.5937Z"
        fill={colours.primaryDarken}
      />
      <path
        d="M42.4028 26.8596V26.7759C42.4028 22.927 39.279 19.8027 35.4298 19.803C31.5809 19.803 28.4569 22.927 28.4572 26.7757V26.8594C28.5129 30.6526 31.6089 33.7486 35.4301 33.7486C39.251 33.7488 42.3473 30.6526 42.4028 26.8596Z"
        fill="#64E1DC"
      />
      <path
        d="M35.4302 33.7486C31.609 33.7486 28.513 30.6526 28.4573 26.8594L42.4029 26.8596C42.3474 30.6526 39.2511 33.7488 35.4302 33.7486Z"
        fill="#00C8C8"
      />
      <path
        d="M48.4836 36.1194C46.5034 33.8326 45.2205 30.5414 45.1925 26.8596V26.7759C45.1925 23.0384 46.4477 19.6913 48.4559 17.4042C49.3205 17.8225 50.1572 18.2966 50.9661 18.7989C53.8947 20.6396 56.5166 23.0104 58.8314 25.8835C59.0546 26.1622 59.1661 26.5248 59.1103 26.8594C59.1103 27.1383 59.0269 27.4174 58.8314 27.6406C56.5446 30.5134 53.8947 32.8562 50.9661 34.6969C50.1572 35.1992 49.3483 35.6733 48.4836 36.1194V36.1194Z"
        fill={colours.secondary}
      />
      <path
        d="M48.4832 36.1194C46.503 33.8326 45.2201 30.5414 45.1921 26.8596L59.1099 26.8594C59.1099 27.1383 59.0262 27.4172 58.831 27.6406C56.5437 30.5134 53.8943 32.8562 50.9657 34.697C50.1568 35.1993 49.3479 35.6733 48.4832 36.1194V36.1194Z"
        fill={colours.secondaryDarken}
      />
      <path
        d="M22.4884 27.752C22.7118 27.5009 22.8233 27.1942 22.8233 26.8594C22.879 26.4688 22.7675 26.0507 22.4886 25.7718C18.8906 22.1178 16.7987 22.5918 16.3804 22.5921C4.30351 22.5921 1.26298 25.4929 0.956357 25.7995C0.705206 26.0784 0.565506 26.4133 0.565763 26.7756C0.565763 26.8034 0.565763 26.8316 0.593754 26.8596C0.593754 27.1385 0.705206 27.4174 0.900375 27.6403C1.3467 28.1981 4.24727 30.9595 16.3801 30.9595C19.1138 30.9597 20.4805 29.7882 22.4884 27.752Z"
        fill={colours.secondary}
      />
      <path
        d="M22.4882 27.752C20.4803 29.7882 19.1136 30.9597 16.38 30.9595C4.24713 30.9595 1.34656 28.1981 0.900496 27.6406C0.70507 27.4174 0.593619 27.1385 0.593619 26.8596L22.8234 26.8596C22.8231 27.1942 22.7117 27.5009 22.4882 27.752Z"
        fill={colours.secondaryDarken}
      />
    </svg>
  );
};
